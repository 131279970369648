@charset "UTF-8";
body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Roboto Condensed", sans-serif;
  background: #ffffff;
}

@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Extralight.woff2") format("woff2"),
    url("./font/ClashDisplay-Extralight.woff") format("woff"),
    url("./font/ClashDisplay-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./font/source-sans-pro.regular.ttf") format("truetype"),
       url("./font/source-sans-pro.extralight.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Semibold.woff2") format("woff2"),
    url("./font/ClashDisplay-Semibold.woff") format("woff"),
    url("./font/ClashDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Medium.woff2") format("woff2"),
    url("./font/ClashDisplay-Medium.woff") format("woff"),
    url("./font/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Bold.woff2") format("woff2"),
    url("./font/ClashDisplay-Bold.woff") format("woff"),
    url("./font/ClashDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Regular.woff2") format("woff2"),
    url("./font/ClashDisplay-Regular.woff") format("woff"),
    url("./font/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Light.woff2") format("woff2"),
    url("./font/ClashDisplay-Light.woff") format("woff"),
    url("./font/ClashDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.displayColumnStart {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: start;
}

.alignCenter{
  display: flex;
  align-items: center;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.displaySpacebetween1 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.logoImg {
  /* width: 180px; */
}

.crypto {
  animation: rotating3 3s linear infinite;
}
@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
}
.sliderBox img {
  width: auto;
  max-width: 165px;
}

/* .apexcharts-tooltip {
  border-radius: 5px;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
  background: #4ed46c;
} */

.chartBox {
  position: relative;
  width: 160px !important;
  height: 100px !important;
  min-height: 100px !important;
}

.ChartFileBox {
  position: relative;
  width: 160px !important;
  height: 80px !important;
  min-height: 80px !important;
}

/* .apexcharts-canvas {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 160px !important;
} */

.MuiList-padding {
  padding: 8px;
}

.gradientBox {
  padding: 1px;
  background: linear-gradient(
      279.31deg,
      #ec1f24 5.51%,
      rgba(144, 56, 255, 0) 11.86%
    ),
    linear-gradient(100.56deg, #ec1f24 6.09%, hsla(0, 0%, 100%, 0) 17.3%),
    linear-gradient(0deg, hsla(0, 0%, 100%, 0.03), hsla(0, 0%, 100%, 0.03));
  border-radius: 10px;
}

/* .apexcharts-text tspan {
  font-family: inherit;
  opacity: 0;
  display: none;
} */

* {
  scrollbar-width: 0px;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-moz-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

.textfiledicons .MuiIconButton-root {
  color: #ec1f24 !important;
  padding: 6px;
  font-size: 17px;
  border: 1.2px solid #c9c9c9;
  margin-left: -4px;
  border-radius: 8px;
  margin-right: 8px;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
}

.tabActiveButtons {
  background: #ec1f24;
  border-radius: 50px;
  padding: 11px 23px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 23px;
  white-space: pre;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}

.supportedBox {
  background: #000;
  padding: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.supportedBox::before {
  content: " ";
  position: absolute;
  /* top: 0px; */
  width: 240px;
  height: 200%;
  animation: animate 10s linear infinite;
  background: rgba(253, 199, 99, 0.25);
}

.barchartBox .apexcharts-text tspan {
  font-family: inherit;
  opacity: 0;
  display: block;
}

/* .apexcharts-toolbar {
  display: none !important;
} */

/* .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  display: none !important;
} */

.borderSpaceTable .MuiTable-root {
  border-collapse: separate;
  border-spacing: 0px 10px;
}
.borderSpaceTable .MuiTableRow-root {
  background: #f4f7ff;
  border-radius: 5px;
}
.borderSpaceTable .MuiTableCell-root {
  padding: 20px 16px;
}

.transactionTable .MuiTableCell-head {
  background: #ffffff;
  font-weight: 300 !important;
  color: #78819f !important;
}
.transactionTable .MuiTableHead-root {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.transactionTable .MuiTableRow-root {
  vertical-align: middle;
  background: #fbfbfb;
}

.react-tel-input .flag-dropdown {
  border-radius: 7px 0 0 7px !important;
  background-color: #ededed;
}

.react-tel-input .flag {
  /* border-radius: 100px; */
}

.react-tel-input .flag-dropdown.open {
  border-radius: 7px 0 0 7px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 7px 0 0 7px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.p2pTable .MuiTableCell-head {
  background: #ffffff;
  font-weight: 300 !important;
  color: #78819f !important;
  padding: 7px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.p2pTable .MuiTableHead-root {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.p2pTable .MuiTableRow-root {
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.MuiAutocomplete-paper {
  padding: 5px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.slidercssBox .slick-dots li button:before {
  font-size: 50px;
  content: "─";
  color: #000;
}
.slidercssBox .slick-dots li {
  width: 30px;
}
.slidercssBox .slick-dots li.slick-active button:before {
  color: #ec1f24;
  opacity: 1;
}
.slidercssBox .slick-dots {
  bottom: -70px;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-13cymwt-control {
  border-radius: 5px !important;
  min-height: 46px !important;
  background-color: #f9f9f9 !important;
  border-color: #eceaea !important;
}

.css-t3ipsp-control {
  min-height: 46px !important;
}

.css-1jqq78o-placeholder {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.css-1dimb5e-singleValue {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.greenbutton {
  color: #ffffff !important;
  font-size: 14px !important;
  background: #4ed46c !important;
  font-style: normal !important;
  text-align: center !important;
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  border-radius: 100px !important;
  padding: 8px 30px !important;
}



.greenbutton:hover {
  background-color: #48e36a;
}

.MuiOutlinedInput .MuiOutlinedInput-root {
  background: #ffffff;
  border-radius: 50px;
}

.css-1nmdiq5-menu {
  font-size: 12px;
}

@media (max-width: 1024px) {
  .transactionTable {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  
}
@media (min-width:960px) {
  .logoImg {
    width: 195x;
  }
}
@media (max-width: 991px) {
  .transactionTable {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 134px;
  }
}
@media (max-width: 767px) {
  .transactionTable {
    display: none;
  }
  .orangeshadeBox {
    display: none;
  }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 134px;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
} /*# sourceMappingURL=main.css.map */
/* add comment for Update */

.capitalize-first-letter *::first-letter {
  text-transform: capitalize !important;
}
